import "./home.css";
import Slider_1 from "./images/slider_1.jpg";
import Slider_2 from "./images/slider_2.jpg";
import Slider_3 from "./images/slider_3.jpg";
import Slider_4 from "./images/slider_4.jpg";
import About from "./images/about.png";
import Logo_1 from "./images/logo_1.jpg";
import Logo_2 from "./images/logo_2.jpg";
import Logo_3 from "./images/logo_3.jpg";
import Logo_4 from "./images/logo_4.jpg";
import Logo_5 from "./images/logo_5.png";
import Logo_6 from "./images/logo_6.png";
import Logo_7 from "./images/logo_7.jpg";
import Logo_8 from "./images/logo_8.jpg";
import Logo_9 from "./images/logo_9.jpg";
import Logo_10 from "./images/logo_10.jpg";
import Logo_11 from "./images/logo_11.jpg";
import Logo_12 from "./images/logo_12.jpg";
import Logo_13 from "./images/logo_13.png";
import Logo_14 from "./images/logo_14.png";
import Logo_15 from "./images/logo_15.jpg";
import Logo_16 from "./images/logo_16.jpg";
import WhatsApp from "./images/whatsapp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHandshake,
  faTachographDigital,
  faLayerGroup,
  faCreditCard,
  faShop,
  faTrowelBricks,
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import CountUp from "react-countup";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
function Home() {
  const [counterState, setcounterState] = useState(false);
  return (
    <>
      <a className="whats-contact" href="https://wsend.co/966532661150" target="_blank" rel="noreferrer">
        <img src={WhatsApp} alt="WhatsApp" className="img-fluid" />
      </a>
      {/* Start Landing */}
      <section className="landing" id="home">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={Slider_1} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Slider_2} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Slider_3} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Slider_4} class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* End Landing */}
      {/* Start Information */}
      <section className="information py-5" id="inform">
        <div className="container">
          <h1 className="main-header">عن أضواء الدروب</h1>
          <div className="content">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  مؤسسة أضواء الدروب للخدمات الإدارية والتسويقية هي مؤسسة وطنية
                  ، تعمل على تقديم الاستشارات والحلول التطويرية المتكاملة
                  التسويقية والمالية والإدارية كما تختص في بناء وتخطيط المشاريع
                  التنموية والأعمال الإدارية. نقدم خدماتنا للشركات والمؤسسات
                  والمستثمرين ورواد الأعمال والأفراد.{" "}
                </p>
                <a href="#foot" className="main-btn">
                  ابدأ معنا
                </a>
              </div>
              <div className="col-lg-6">
                <div className="image">
                  <img src={About} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Information */}
      {/* Start Services */}
      <section className="services-sec pt-5" id="serv">
        <div className="container">
          <h1 className="main-header">خدماتنا</h1>
          <div className="services mt-5 sm-vis">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="service">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faLayerGroup} />
                  </div>
                  <h4 className="text-center">
                    البناء المؤسسي والخدمات الإدارية{" "}
                  </h4>
                  <ul className="mt-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>بناء الخطط ومؤشرات الأداء الاستراتيجية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>قياس وتطوير الأداء المؤسسي للمنظمات. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>بناء وتطوير الهياكل التنظيمية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>بناء السياسات واللوائح والأدلة الإجرائية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>لائحة وهيكلة الرواتب والأجور. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>بناء مصفوفة الصلاحيات. </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service ">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faShop} />
                  </div>
                  <h4 className="text-center">خدمات التسويق </h4>
                  <ul className="my-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>القيام بدراسات الجدوى التسويقية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>صناعة الخطط والمشاريع التسويقية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>بناء وتطوير الهياكل التنظيمية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>دراسة سلوكيات وإتجاهات العملاء المستهدفين. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>التسويق على الجهات والمؤسسات المانحة. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تصميم وتنفيذ الحملات التسويقية.</li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faTrowelBricks} />
                  </div>
                  <h4 className="text-center">تأسيس وتطوير أنظمة الحوكمة</h4>
                  <ul className="mt-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>حوكمة القطاع الثالث.</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>حوكمة الأندية الرياضية.</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>حوكمة القطاع التعليمي.</li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faTachographDigital} />
                  </div>
                  <h4 className="text-center">خدمات التحول التقني</h4>
                  <ul className="mt-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>توفير البرامج السحابية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تصميم وإدارة المواقع والمتاجر الإكترونية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تصميم برامج إلكترونية خاصة. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>برمجة تطبيقات للجوال. </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faHandshake} />
                  </div>
                  <h4 className="text-center">خدمات التأهيل والتدريب</h4>
                  <ul className="mt-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>التأهيل والتدريب لأنظمة الجودة والأيزو</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>التأهيل لجوائز التميز المؤسسي.</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تحليل الإحتياجات التدريبية. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تدريب وتأهيل الكوادر البشرية. </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service">
                  <div className="icon text-center">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </div>
                  <h4 className="text-center">الخدمات المالية </h4>
                  <ul className="mt-4 p-0">
                    <li>
                      <ul className="content d-flex align-items-center p-0">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>مراجعة وتدقيق القوائم المالية السنوية.</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>استرداد الضريبة المضافة للمنشأت المؤهلة.</li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>تحليل وتقييم الوضع المالي للمنشأت. </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="content d-flex align-items-center">
                        <li className="ms-2">
                          <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li>
                          الإشراف الميداني لاعداد الميزانيات المالية والتقارير
                          الربعية السنوية.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#foot" className="main-btn d-block mx-auto">
                  ابدأ معنا
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Services */}
      {/* Start Clients */}
      <section className="clients-sec py-5" id="client">
        <div className="container">
          <h1 className="main-header">أبرز العملاء</h1>
          <div className="clients-container">
            <Swiper
              className="sample-slider"
              modules={[Autoplay]}
              loop={true}
              autoplay={{
                delay: 0,
                pauseOnMouseEnter: false, // added
                disableOnInteraction: false,
                allowTouchMove: false,
              }}
              slidesPerView={5}
              speed={3000}
            >
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_1} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_2} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_3} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_4} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_5} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_6} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_7} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_8} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_9} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_10} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_11} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_12} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_13} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_14} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_15} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="logo">
                  <img src={Logo_16} className="img-fluid" alt="logo" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      {/* End Clients */}
      {/* Start Data */}
      <section className="data-sec pt-5" id="data">
        <div className="container">
          <ScrollTrigger
            onEnter={() => setcounterState(true)}
            onExit={() => setcounterState(false)}
          >
            <div className="row">
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>إستشارت مالية</h4>
                  <h3>
                    {counterState && (
                      <CountUp start={0} end={12} duration={4} />
                    )}{" "}
                    جهة
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>تصميم مبادرات تسويقية</h4>
                  <h3>
                    {counterState && <CountUp start={0} end={7} duration={4} />}{" "}
                    جهات
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>بناء مؤسسي </h4>
                  <h3>
                    {counterState && <CountUp start={0} end={5} duration={4} />}{" "}
                    جهات
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>حوكمة القطاع الثالث</h4>
                  <h3>
                    {counterState && <CountUp start={0} end={9} duration={4} />}{" "}
                    جهات
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>تحول تقني</h4>
                  <h3>
                    {counterState && <CountUp start={0} end={4} duration={4} />}{" "}
                    جهات
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>حملات تسويقية</h4>
                  <h3>
                    {counterState && <CountUp start={0} end={4} duration={4} />}
                  </h3>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="data">
                  <h4>تأهيل لجوئز التميز</h4>
                  <h3>
                    {counterState && <CountUp start={0} end={2} duration={4} />}
                  </h3>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </section>
      {/* End Data */}
      {/* Start footer */}
      <section className="footer py-5" id="foot">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact">
                <ul>
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>
                    <div className="text">Saudi Arabia, Qassim, Unayzah</div>
                  </li>
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="text">0554811692 - 0536180045</div>
                  </li>
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <div className="text">Adwaadroub@gmail.com</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-4">Contact Us With Social Media :</h4>
              <ul className="brand">
                <li>
                  <a href="https://x.com/adwaadroub?s=20" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                </li>
                <li>
                  <a href="https://wsend.co/966532661150" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copy">Adwaadroub &copy; 2023</div>
        </div>
      </section>
      {/* End footer */}
    </>
  );
}
export default Home;
