import Logo from "./Logo.png";
import "./Navbar.css";
function Navbar() {
  return (
    <>
      {/* Start NavBar */}
      <nav class="navbar navbar-expand-lg ">
        <div class="container shadow-sm">
          <img class="navbar-brand img-logo" src={Logo} alt="Logo" />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto p-0 mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="#home">
                  الرئيسية
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#inform">
                  عن المؤسسة
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#serv">
                  خدماتنا
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#client">
                  عملاؤنا
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#foot">
                  تواصل معنا
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* End NavBar */}
    </>
  );
}
export default Navbar;
