import Home from "./Home/Home"
import Navbar from "./Navbar/Navbar"

function App () {
  return (
    <>
    <Navbar/>
    <Home />
    </>
  )
}
export default App